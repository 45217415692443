import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { categories } from '../../../data/dummyData';

const CategoriesDetails = () => {
  const categoryContainer = useRef(null);
  const [propertyType, setPropertyType] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    if (propertyType) {
      navigate(`/property?propertyType=${propertyType}`);
    }
  };

  const handleCategoryClick = (cat) => {
    setPropertyType(cat);
    handleSearch(); // Navigate after setting property type
  };

  // Slice the categories array from index 1 to 7 (end index is exclusive)
  const displayedCategories = categories.slice(0, 8);

  return (
    <div className="pt-10 pb-16">
      <div className="flex justify-center md:justify-start gap-3 mt-4 flex-wrap">
        {displayedCategories.map(({ id, name, image, cat }) => (
          <div
            key={id}
            className="relative flex-shrink-0 w-[300px] group rounded-lg"
          >
            <div
              onClick={() => handleCategoryClick(cat)}
              role="button"
              tabIndex={0}
              className="overflow-hidden rounded-lg cursor-pointer"
              onKeyDown={(e) => e.key === 'Enter' && handleCategoryClick(cat)} 
            >
              <img
                src={image}
                alt={name}
                className="w-full h-[300px] object-cover group-hover:scale-125 transition-transform"
              />
            </div>
            <div className="absolute bottom-0 left-0 w-full px-2 py-2 bg-gradient-to-t from-black/80 text-slate-100 to-transparent">
              <h1 className="text-lg font-semibold">{name}</h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesDetails;
