import React, { useState, useEffect } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import ShapeLineOutlinedIcon from '@mui/icons-material/ShapeLineOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import SearchIcon from '@mui/icons-material/Search';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import '../Filter.css';

const MoreFilters = ({
  activeDropdown,
  toggleDropdown,
  handleFurnishingClick,
  minArea,
  setMinArea,
  maxArea,
  setMaxArea,
  keywords,
  setKeywords,
  handleMoreFiltersSubmit,
  setActiveDropdown,
  purpose,
}) => {
  const initialState = {
    selectedAmenities: [],
    selectedVirtualViewings: [],
    selectedFurnishing: null,
  };

  const [formState, setFormState] = useState(initialState);
  const modalRef = React.useRef();

  const toggleAmenity = (amenity) => {
    setFormState((prev) => ({
      ...prev,
      selectedAmenities: prev.selectedAmenities.includes(amenity.value)
        ? prev.selectedAmenities.filter((a) => a !== amenity.value)
        : [...prev.selectedAmenities, amenity.value],
    }));
  };

  const selectVirtualViewing = (viewing) => {
    setFormState((prev) => ({
      ...prev,
      selectedVirtualViewings: prev.selectedVirtualViewings.includes(viewing)
        ? prev.selectedVirtualViewings.filter((v) => v !== viewing)
        : [viewing],
    }));
  };

  const resetFilters = () => {
    setFormState(initialState);
    setMinArea('');
    setMaxArea('');
    setKeywords('');
  };

  const virtualViewingOptions = ["All Virtual Viewings", "360 Tours", "Video Tours", "Live Viewings"];
  const furnishingOptions = ["All Furnishings", "Furnished", "Unfurnished", "Partly Furnished"];
  const amenitiesContent = [
    { name: "Central A/C", value: "Central A/C" },
    { name: "Maids Room", value: "Maids Room" },
    { name: "Balcony", value: "Balcony" },
    { name: "Shared Pool", value: "Shared Pool" },
    { name: "View of Water", value: "View of Water" },
    { name: "View of Landmark", value: "View of Landmark" },
    { name: "Pets Allowed", value: "Pets Allowed" },
    { name: "Study", value: "Study" },
    { name: "Shared Gym", value: "Shared Gym" },
    { name: "Parking", value: "Parking" },
    { name: "Garden", value: "Garden" },
    { name: "Security", value: "Security" },
    { name: "Sauna", value: "Sauna" },
    { name: "Children's Play Area", value: "Children's Play Area" },
    { name: "Covered Parking", value: "Covered Parking" },
  ];

  const selectedCount = formState.selectedAmenities.length + formState.selectedVirtualViewings.length + (minArea || maxArea ? 1 : 0) + (keywords ? 1 : 0);

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setActiveDropdown(null);
    }
  };

  useEffect(() => {
    if (activeDropdown === 'moreFilters') {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeDropdown]);

  return (
    <div onClick={() => toggleDropdown('moreFilters')} className={`flex justify-between border rounded-lg w-full md:w-52 dark:bg-dark-light px-2 py-3 bg-slate-100 items-center cursor-pointer ${selectedCount ? "bg-primary/10 border" : ""}`} aria-expanded={activeDropdown === 'moreFilters'}>
      <button className="border-b-2 border-transparent">
        More Filters {selectedCount > 0 && `(${selectedCount})`}
      </button>
      {activeDropdown === 'moreFilters' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}

      {activeDropdown === 'moreFilters' && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div ref={modalRef} className="bg-white dark:bg-dark md:h-auto h-5/6 p-5 rounded-lg shadow-lg overflow-y-auto" onClick={(e) => e.stopPropagation()}>
            <div className="flex justify-between items-center border-b pb-2 mb-4">
              <h2 className="font-bold text-3xl">More Filters</h2>
              <button className="cursor-pointer text-xl" onClick={() => setActiveDropdown(null)}>✖</button>
            </div>

            <form onSubmit={(e) => {
              e.preventDefault();
              handleMoreFiltersSubmit({
                minArea,
                maxArea,
                keywords,  // Include keywords here
                selectedAmenities: formState.selectedAmenities,
                selectedVirtualViewings: formState.selectedVirtualViewings,
                selectedFurnishing: formState.selectedFurnishing,
              });
              setActiveDropdown(null);
            }}>
              {/* Furnishing Filter */}
              <div className="mb-4">
                <label className="text-2xl"><BedOutlinedIcon /> Furnishing</label>
                <div className="flex gap-2 mb-4 mt-2 flex-wrap">
                  {furnishingOptions.map(option => (
                    <button 
                      key={option} 
                      className={`btn w-full md:w-auto bg-gray-200 dark:bg-dark-light dark:hover:bg-slate-400 transition-colors p-2 border px-4 rounded-lg ${formState.selectedFurnishing === option ? 'bg-primary text-white' : 'bg-gray-200'}`}
                      onClick={() => {
                        setFormState((prev) => ({ ...prev, selectedFurnishing: option }));
                        handleFurnishingClick(option);
                      }}>
                      {option}
                    </button>
                  ))}
                </div>
              </div>

              {/* Property Size */}
              <div className="mb-4">
                <label className="text-2xl"><ShapeLineOutlinedIcon /> Property Size (sqft)</label>
                <div className="flex space-x-2 mt-2">
                  <input type="number" className="border rounded p-2 w-full md:w-44 number-input" placeholder="Min. Area" value={minArea} onChange={e => setMinArea(e.target.value)} />
                  <input type="number" className="border rounded p-2 w-full md:w-44 number-input" placeholder="Max. Area" value={maxArea} onChange={e => setMaxArea(e.target.value)} />
                </div>
              </div>

              {/* Amenities - Conditional Rendering */}
              {(purpose !== 'CommercialRent' && purpose !== 'CommercialSale') && (
                <div className="mb-4">
                  <label className="text-2xl"><DiamondOutlinedIcon /> Amenities</label>
                  <div className="grid grid-cols-1 mt-2 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {amenitiesContent.map(amenity => (
                      <label key={amenity.value} className="flex items-center hover:text-primary">
                        <input 
                          type="checkbox" 
                          checked={formState.selectedAmenities.includes(amenity.value)} 
                          onChange={() => toggleAmenity(amenity)} 
                          className="mr-2 cursor-pointer" 
                        />
                        {amenity.name}
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {/* Keywords */}
              <div className="mb-4">
                <label className="text-2xl"><SearchIcon /> Keywords</label>
                <div>
                  <input 
                    type="text" 
                    className="shadow-sm mt-2 border rounded-full bg-slate-100 p-2 w-full md:w-96" 
                    placeholder="e.g. beach, chiller free" 
                    value={keywords} 
                    onChange={e => setKeywords(e.target.value)} 
                  />
                </div>
              </div>

              {/* Virtual Viewings */}
              <div className="mb-4">
                <label className="text-2xl mb-5 mt-2"><PlayCircleFilledWhiteOutlinedIcon /> Virtual Viewings</label>
                <div className="flex gap-2 mb-2 mt-2 flex-wrap">
                  {virtualViewingOptions.map(option => (
                    <button 
                      key={option} 
                      className={`btn w-full md:w-auto bg-gray-200 dark:bg-dark-light dark:hover:bg-slate-400 transition-colors p-2 border px-4 rounded-lg ${formState.selectedVirtualViewings.includes(option) ? 'bg-primary text-white' : 'bg-gray-200'}`} 
                      onClick={() => selectVirtualViewing(option)}>
                      {option}
                    </button>
                  ))}
                </div>
              </div>

              <div className="flex justify-end border-t pt-2">
                <button type="button" className="btn btn-primary mr-2" onClick={resetFilters}>Reset</button>
                <button type="submit" className="btn btn-primary">Apply Filters</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MoreFilters;
