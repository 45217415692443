import React from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const AreaDropdown = ({
  activeDropdown,
  handleDropdownToggle,
  minArea,
  setMinArea,
  maxArea,
  setMaxArea,
  selectedPurpose,
}) => {
  const handleReset = () => {
    setMinArea(""); // Resetting minimum area
    setMaxArea(""); // Resetting maximum area
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      onClick={() => handleDropdownToggle('area')}
      className={`relative flex justify-between border rounded-lg md:w-52 w-full px-2 dark:bg-dark-light py-3 bg-slate-100 items-center cursor-pointer ${minArea || maxArea ? 'border bg-primary/10' : ''}`}
      aria-haspopup="true"
      aria-expanded={activeDropdown === 'area'}
    >
      <button className={`border-transparent`}>
        {minArea ? `Min ${minArea.slice(0, 3)}..` : ''} 
        {maxArea ? ` - Max ${maxArea.slice(0, 3)}..` : ' Area Sqft'}
      </button>

      {activeDropdown === 'area' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}

      {activeDropdown === 'area' && (
      <div className="bg-white dark:bg-dark-light border  absolute top-full left-0  p-4 rounded shadow-md z-10">
      <div className="flex flex-col sm:flex-row gap-4 mt-3">
        <div className="flex flex-col w-full">
          <input
            type="number"
            value={minArea}
            onChange={(e) => setMinArea(e.target.value)}
            onClick={handleInputClick}
            className="number-input dark:bg-dark-light border rounded p-2"
            placeholder="Min. Area (sqft)"
            aria-label="Minimum Area"
            min="0"
          />
        </div>
        <div className="flex flex-col w-full">
          <input
            type="number"
            value={maxArea}
            onChange={(e) => setMaxArea(e.target.value)}
            onClick={handleInputClick}
            className="number-input border dark:bg-dark-light rounded p-2"
            placeholder="Max. Area (sqft)"
            aria-label="Maximum Area"
            min="0"
          />
        </div>
      </div>
    
      <div className="flex justify-between dark:text-slate-50 mt-2">
        <button onClick={handleReset} className="text-gray-600 dark:text-slate-50">
          Reset
        </button>
        <div
          onClick={() => {
            // Optionally handle confirming the selection
          }}
          className="text-primary cursor-pointer"
          aria-label="Confirm selection"
        >
          <CheckCircleOutlineIcon />
        </div>
      </div>
    </div>
    
      )}
    </div>
  );
};

export default AreaDropdown;
