
import {
  AboutUs,
} from "../components/common/page-componets";
// import HeroCarousel from "../components/common/page-componets/HeroCurosal";


const About = () => {
  return (
    <>
      {/* <HeroCarousel /> */}
      <div className="pt-48 px-[3%] md:px-[6%]">
        <h1 className="text-[12px]">
          HOME / <span className="text-primary">ABOUT US</span>
        </h1>
        <AboutUs />
      </div>
    </>
  );
};

export default About;
