const cardLabels = ({ purpose, distance }) => {
  return (
    <div className="absolute top-2 left-4 flex-align-center gap-x-48">
      <span className="py-[3px] px-6 text-sm rounded-full md:text-base capitalize text-white bg-slate-500">
        {purpose}
      </span>
    </div>
  );
};

export default cardLabels;
