import React, { useState } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const PriceDropdown = ({
  activeDropdown,
  handleDropdownToggle,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  selectedPurpose,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState(""); // State for selected rental period
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  const handleReset = () => {
    setMinPrice("");
    setMaxPrice("");
    setSelectedPeriod("");
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const rentalPeriods = ["Yearly", "Monthly", "Weekly", "Daily"];

  const filteredPeriods = rentalPeriods.filter(period =>
    period.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      onClick={() => handleDropdownToggle('price')}
      className={`relative flex justify-between border rounded-lg w-full md:w-52 px-2 dark:bg-dark-light py-3 bg-slate-100 items-center cursor-pointer ${minPrice || maxPrice ? 'border bg-primary/10' : ''}`}
      aria-haspopup="true"
      aria-expanded={activeDropdown === 'price'}
    >
      <button className={`border-transparent`}>
        {minPrice ? `Min ${minPrice.slice(0, 3)}..` : ''} 
        {maxPrice ? ` - Max ${maxPrice.slice(0, 3)}..` : 'Price'}
      </button>

      {activeDropdown === 'price' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}

      {activeDropdown === 'price' && (
        <div className="bg-white dark:bg-dark-light border absolute top-full left-0 w-full md:w-96 p-4 rounded shadow-md z-10">
          <div className="flex gap-4 mt-3 flex-col md:flex-row">
            <div className="flex flex-col w-full">
              <input
                type="number"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
                onClick={handleInputClick}
                className="number-input dark:bg-dark-light border rounded p-2 w-full"
                placeholder="Min. Price (AED)"
                aria-label="Minimum Price"
                min="0"
              />
            </div>
            <div className="flex flex-col w-full">
              <input
                type="number"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
                onClick={handleInputClick}
                className="number-input border dark:bg-dark-light rounded p-2 w-full"
                placeholder="Max. Price (AED)"
                aria-label="Maximum Price"
                min="0"
              />
            </div>
          </div>

          {/* {selectedPurpose === 'Rent' && ( 
            <>
              <h1 className="p-2 md:text-xl">Rental Period</h1>
        
              <div className="flex gap-2 mb-2 flex-wrap">
                {filteredPeriods.map((period) => (
                  <button
                    key={period}
                    onClick={() => {
                      setSelectedPeriod(period);
                      console.log(`Selected rental period: ${period}`);
                    }}
                    className={`transition-colors p-2 border px-4 rounded-lg ${selectedPeriod === period ? 'bg-primary text-white' : 'bg-gray-200 dark:bg-dark-light hover:bg-gray-300'}`}
                  >
                    {period}
                  </button>
                ))}
              </div>
              <hr />
            </>
          )} */}

          <div className="flex justify-between dark:text-slate-50 mt-2">
            <button onClick={handleReset} className="text-gray-600 dark:text-slate-50">
              Reset
            </button>
            <div
              onClick={() => {
                // Optionally handle confirming the selection
                console.log(`Selected period: ${selectedPeriod}`);
              }}
              className="text-primary cursor-pointer"
              aria-label="Confirm selection"
            >
              <CheckCircleOutlineIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceDropdown;

