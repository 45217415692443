import { services } from "../../../data/dummyData";

const Services = () => {
  return (
    <div className="pt-20 pb-16">
      <div className="text-center">
        <h1 className="heading">
          OUR SERVICES
        </h1>
        {/* <h1 className="mx-auto sub-heading">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam voluptatem ex nisi minima quia eius earum porro, maxime ullam sed autem ea aliquam minus quos fugit ut nemo eos error.</h1> */}
      </div>
      <div className="grid grid-cols-1 gap-2 mt-5 sm:grid-cols-2 md:grid-cols-4">
        {services.map(({ id, name, icon, text }) => (
          // <div

          //   className="p-3 text-center rounded-lg hover:card-shadow border-b-4 border-b-transparent hover:border-b-4 hover:border-b-[#0ea5e9] dark:hover:bg-card-dark cursor-pointer"
          //   key={id}
          // >
          <div
            className="p-3 text-center rounded-lg  border-b-4 border-b-transparent "
            key={id}
          >
            <div className="icon-box !opacity-100 !w-14 !h-14 mx-auto !bg-[#0ea5e9]/20 text-[#0ea5e9] cursor-none">
              <div className="text-2xl"> {icon}</div>
            </div>
            <h1 className="mt-2 heading !text-xl">{name}</h1>
            <p className="mt-2 text-center">{text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
