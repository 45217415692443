import React, { useEffect } from 'react';
import {
  Featured,
  Services,
  Categories
} from "../components/common/page-componets";
import Testimonial from "../components/common/Testimonial";
import {
  Feeds,
  Filters,
  Hero
} from "../components/home/home";
import 'wow.js/css/libs/animate.css'; 
import WOW from 'wow.js';


const Home = () => {
  useEffect(() => {

    const wow = new WOW({
      live: false,
      offset: 100,
      mobile: true
    });
    wow.init();

    
    return () => {
    };
  }, []);

  return (
    <>
      <Hero />
      <div className="pt-16 px-[3%] md:px-[6%]">
            <Filters />
      
        
            <Services />
      
            <Featured />
        
        
            <Feeds />
        
        
         
            <Categories />
         
        
          <div id="testimonial">
            <Testimonial />
          </div>

      </div>
    </>
  );
};

export default Home;
