import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LocationSearch from './LocationSearch';
import PurposeDropdown from './PurposeDropdown';
import PropertyTypeDropdown from './PropertyType';
import BedsAndBathsDropdown from './BedsAndBathsDropdown';
import PriceDropdown from './PriceDropdown';
import MoreFiltersModal from './MoreFilter';
import AreaModal from './AreaDropDown';
import '../Filter.css';

const FilterNext = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [price, setPrice] = useState({ min: '', max: '' });
  const [area, setArea] = useState({ min: '', max: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [purpose, setPurpose] = useState('');
  const [rental, setRental] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [beds, setBeds] = useState(null); // Change to single value
  const [baths, setBaths] = useState(null); // Change to single value
  const [keywords, setKeywords] = useState('');
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedFurnishing, setSelectedFurnishing] = useState(null);

  const handleSearch = () => {
    if (price.min && price.max && parseFloat(price.min) > parseFloat(price.max)) {
      setErrorMessage("Minimum price cannot exceed maximum price.");
      return;
    }
    if (area.min && area.max && parseFloat(area.min) > parseFloat(area.max)) {
      setErrorMessage("Minimum area cannot exceed maximum area.");
      return;
    }
    setErrorMessage('');

    // Constructing the search URL with all parameters
    navigate(`/property?location=${encodeURIComponent(location)}&minPrice=${encodeURIComponent(price.min)}&maxPrice=${encodeURIComponent(price.max)}&minArea=${encodeURIComponent(area.min)}&maxArea=${encodeURIComponent(area.max)}&purpose=${encodeURIComponent(purpose)}&propertyType=${encodeURIComponent(propertyType)}&beds=${encodeURIComponent(beds)}&baths=${encodeURIComponent(baths)}&keywords=${encodeURIComponent(keywords)}&amenities=${encodeURIComponent(selectedAmenities.join(','))}&furnishing=${encodeURIComponent(selectedFurnishing)}`);
  };

  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown(prev => (prev === dropdown ? null : dropdown));
  };

  const handleBedsAndBathsSelect = (selectedBeds, selectedBaths) => {
    setBeds(selectedBeds);
    setBaths(selectedBaths);
  };

  return (
    <div className="relative">
      <div className="h-auto border bg-white gap-4 dark:bg-dark-light backdrop-blur-1xl m-auto flex flex-wrap items-center justify-around pt-5 pb-5 px-5 shadow-xl md:rounded-3xl rounded-sm">
        <LocationSearch location={location} setLocation={setLocation} />
        <PurposeDropdown 
          activeDropdown={activeDropdown} 
          handleDropdownToggle={handleDropdownToggle} 
          onPurposeSelect={setPurpose} 
        />
        <PropertyTypeDropdown 
          activeDropdown={activeDropdown} 
          handleDropdownToggle={handleDropdownToggle} 
          onPropertyTypeSelect={setPropertyType} 
          selectedPurpose={purpose}
        />
        
        {purpose === 'CommercialRent' || purpose === 'CommercialSale' ? (
          <AreaModal 
            activeDropdown={activeDropdown}
            handleDropdownToggle={handleDropdownToggle}
            minArea={area.min}
            setMinArea={(value) => setArea({ ...area, min: value })}
            maxArea={area.max}
            setMaxArea={(value) => setArea({ ...area, max: value })}
          />
        ) : (
          <BedsAndBathsDropdown 
            activeDropdown={activeDropdown} 
            handleDropdownToggle={handleDropdownToggle} 
            onSelectionChange={handleBedsAndBathsSelect} 
          />
        )}

        <PriceDropdown 
          activeDropdown={activeDropdown} 
          handleDropdownToggle={handleDropdownToggle} 
          minPrice={price.min} 
          setMinPrice={(value) => setPrice({ ...price, min: value })} 
          maxPrice={price.max} 
          setMaxPrice={(value) => setPrice({ ...price, max: value })} 
          selectedPurpose={purpose} 
        />
        
        <MoreFiltersModal 
          activeDropdown={activeDropdown} 
          toggleDropdown={handleDropdownToggle} 
          setActiveDropdown={setActiveDropdown} 
          minArea={area.min} 
          setMinArea={(value) => setArea({ ...area, min: value })} 
          maxArea={area.max} 
          setMaxArea={(value) => setArea({ ...area, max: value })} 
          purpose={purpose} 
          keywords={keywords} 
          setKeywords={setKeywords} 
          selectedAmenities={selectedAmenities} 
          setSelectedAmenities={setSelectedAmenities} 
          selectedFurnishing={selectedFurnishing} 
          setSelectedFurnishing={setSelectedFurnishing} 
          handleMoreFiltersSubmit={({ minArea, maxArea, keywords, selectedAmenities, selectedFurnishing }) => {
            setArea({ min: minArea, max: maxArea });
            setKeywords(keywords);
            setSelectedAmenities(selectedAmenities);
            setSelectedFurnishing(selectedFurnishing);
          }} 
        />

        <button className="btn h-12 md:w-32 w-full rounded-r-full overflow-hidden bg-primary text-white" onClick={handleSearch}>
          Search
        </button>
      </div>

      {errorMessage && <div className="text-red-500">{errorMessage}</div>}
    </div>
  );
};

export default FilterNext;
