import React, { useState, useEffect } from "react";

const Map = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const handleLoad = () => setShowLoader(false);
    window.addEventListener("load", handleLoad);
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  return (
    <>
      {showLoader && (
        <div className="flex justify-center ">
          <div className="custom-loader">
            <div className="loader"></div>
          </div>
        </div>
      )}
      <div className="rounded-lg">
        <iframe
          className="rounded-lg"
          title="Google Map"
           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.0298470658727!2d55.26549477599715!3d25.202215931496163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f427da5f99009%3A0xac7f3db7c2d5e4a4!2s144%20Sheikh%20Zayed%20Rd%20-%20Al%20Wasl%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1728544679009!5m2!1sen!2s" 
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          onLoad={() => setShowLoader(false)}
        ></iframe>
      </div>
    </>
  );
};

export default Map;
