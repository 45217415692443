import React from 'react'
import PropertyDetailPage from '../components/property/property-4/PropertyDetailPage'

const PropertyDetailList = () => {
  return (
    <div>
      <PropertyDetailPage />
    </div>
  )
}

export default PropertyDetailList
