import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

const LocationSearch = ({ location, setLocation }) => (
  <div className="flex items-center  dark:bg-dark-light bg-slate-100 border rounded-full px-5 w-full md:w-auto">
    <SearchIcon />
    <input
      type="text"
      placeholder="Enter Location"
      onChange={(event) => setLocation(event.target.value)}
      className="outline-none bg-transparent px-2 h-12 md:h-14 flex-grow" // Responsive height and full width
    />
  </div>
);

export default LocationSearch;




















    {/* Light Mode Search */}
    // <div className="flex items-center border-light dark:hidden dark:bg-dark-light bg-slate-100 rounded-full px-5">
    //   <SearchIcon />
    //   <Autocomplete
    //     disablePortal
    //     options={DubaiLocations}
    //     getOptionLabel={(option) => option.title}
    //     renderInput={(params) => (
    //       <TextField
    //         {...params}
    //         placeholder="Enter Location"
    //         variant="outlined"
    //         InputProps={{
    //           ...params.InputProps,
    //           disableUnderline: true,
    //         }}
    //         sx={{
    //           width: '300px',
    //           '& .MuiOutlinedInput-root': {
    //             '& fieldset': {
    //               border: 'none',
    //             },
    //           },
    //         }}
    //       />
    //     )}
    //     renderOption={(props, option) => (
    //       <li {...props}>
    //         <RoomOutlinedIcon style={{ marginRight: 8 }} />
    //         {option.title}
    //       </li>
    //     )}
    //     onChange={(event, value) => setLocation(value ? value.title : '')}
    //   />
    // </div>