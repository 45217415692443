import React from 'react';
import SingleProductCard from "../../common/page-componets/SingleProductCard";
import { useSearchParams } from "react-router-dom";
import nodata from '../../../Assets/images/nodatafoundimg.avif';
import { property } from "../../../data/dummyData";

const PropertyList = ({ basis }) => {
 // Assuming you're using URLSearchParams to get the search parameters
const [searchParams] = useSearchParams();

// Initialize params object
const params = {
  location: '',
  propertyType: '',
  purpose: '',
  beds: '',
  baths: '',
  minPrice: '',
  maxPrice: '',
  minArea: '',
  maxArea: '',
  selectedAmenities: [],
  furnishing: ''
};

// Map search parameters to the params object
searchParams.forEach((value, key) => {
  if (key === 'amenities') {
    // Split amenities by comma and trim whitespace
    params.selectedAmenities = value.split(',').map(amenity => amenity.trim());
  } else {
    params[key] = value === 'null' ? '' : value;
  }
});

// Filter properties based on params
const filteredProperties = property.filter(val => {
  const matchesPropertyType = !params.propertyType || val.propertyType.toLowerCase() === params.propertyType.toLowerCase();
  const matchesBeds = !params.beds || val.number_of_beds === Number(params.beds);
  const matchesBaths = !params.baths || val.number_of_bathrooms === Number(params.baths);
  const matchesLocation = !params.location || val.location.toLowerCase().includes(params.location.toLowerCase());

  const matchesPurpose = !params.purpose || 
    (params.purpose.toLowerCase() === "buy" && val.purpose.toLowerCase().includes("sale")) ||
    (params.purpose.toLowerCase() === "sale" && val.purpose.toLowerCase().includes("buy")) ||
    (params.purpose.toLowerCase() === "commercialrent" && val.purpose.toLowerCase().includes("rent")) ||
    (params.purpose.toLowerCase() === "commercialsale" && val.purpose.toLowerCase().includes("sale")) ||
    val.purpose.toLowerCase().includes(params.purpose.toLowerCase()) ||
    val.puposesearch.toLowerCase().includes(params.purpose.toLowerCase());

  const matchesPrice = (!params.minPrice || val.price >= Number(params.minPrice)) && 
                       (!params.maxPrice || val.price <= Number(params.maxPrice));
  const matchesArea = (!params.minArea || val.dimensions >= Number(params.minArea)) && 
                      (!params.maxArea || val.dimensions <= Number(params.maxArea));
  
  const matchesAmenities = !params.selectedAmenities.length || 
                           params.selectedAmenities.some(amenity => 
                             val.amenities.map(a => a.toLowerCase()).includes(amenity.toLowerCase())
                           );

  return matchesPropertyType && matchesLocation && matchesPurpose && matchesBeds && matchesBaths && matchesArea && matchesAmenities && matchesPrice;
});


  return (
    <div className="flex flex-wrap gap-4 justify-center">
      {filteredProperties.length > 0 ? (
        filteredProperties.map(property => (
          <SingleProductCard key={property.id} {...property} basis={basis} />
        ))
      ) : (
        <div className="w-full flex justify-center">
          <img className="object-contain h-96 w-full max-w-md" src={nodata} alt="No data found" />
        </div>
      )}
    </div>
  );
};

export default PropertyList;
