import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { testimonials } from "../../data/dummyData";

const Testimonial = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = testimonials.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index]);

  useEffect(() => {
    const slider = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 3000);
    return () => clearInterval(slider);
  }, []);

  return (
    <div className="pt-20 pb-16">
      <div className="text-center">
        <h1 className="heading">What Clients Think About Us</h1>
      </div>
      <div className="relative overflow-hidden">
        <div className="flex transition-transform duration-1000" style={{ transform: `translateX(-${index * 100}%)` }}>
          {testimonials.map((testimonial) => {
            const { id, image, name, reviewText } = testimonial;
            return (
              <div
                className="w-full md:w-1/2 px-4 flex-shrink-0"
                key={id}
              >
                <div className="flex flex-col items-center h-full p-6">
                  <img
                    src={image}
                    alt={name}
                    className="object-cover -bottom-12 relative w-28 h-28 mx-auto rounded-full shadow-xl bg-white"
                  />
                  <div className="px-10 pb-5 info border rounded-2xl bg-slate-200 dark:bg-slate-900">
                    <h1 className="mt-4 pt-10 uppercase text-md"></h1>
                    <p className="flex justify-center">
                      <ReactStars
                        size={18}
                        isHalf={true}
                        activeColor="#ffd700"
                        value={4.5}
                        edit={false}
                      />
                    </p>
                    <p className="mt-2 italic">"{reviewText}"</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
