import { feeds } from "../../../data/dummyData";
import SingleFeedCardGrid from "../../common/page-componets/SingleFeedCardGrid";
import image from '../../../Assets/images/blog-main-image.jpg'
import { Link } from "react-router-dom";
const Feeds = () => {
  return (
    <div className="pt-10 pb-16">
      <div className="text-center">
        <h1 className="heading">OUR BLOGS</h1>
        {/* <h1 className="mx-auto sub-heading">Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae expedita rem reprehenderit recusandae, nam eligendi dicta ipsum quae magni doloribus quibusdam unde praesentium sapiente iure eveniet. Officiis fuga molestias velit.</h1> */}
      </div>
      <div className="grid  gap-4">
        {/* <div className="md:grid   hidden  gap-4 mt-8 md:grid-cols-1">
          <img className="h-[100%] rounded-lg" src={image} alt="" />
        </div> */}
        <div className="grid gap-4 mt-8 md:grid-cols-2">
          {feeds.slice(1, 5).map((feed) => (
            <SingleFeedCardGrid key={feed.id} {...feed} />
          ))}

        </div>
        <div className="flex justify-end">
          <Link to="/blog">
            <button className="w-full mt-4 btn btn-primary">Visit Blogs</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Feeds;
