

import CategoriesDetails from "../components/common/page-componets/CategoriesDetails";

const Category = () => {
  return (
    <>

      <div className="pt-48 px-[3%] md:px-[6%]">
        <h1 className="text-[12px]">HOME / <span className="text-primary">PROPERTY CATEGORIES</span></h1>


        <CategoriesDetails />

      </div>

    </>
  )
}

export default Category
