import { useState } from "react";
import { FiDelete } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {

  HeadeFilters,
  Pagination,
} from "../components/common/page-componets";
import { PropertyList } from "../components/property";
import { property as dummyProperties } from "../data/dummyData";
import { closeFilterMenu, uiStore } from "../features/uiSlice";
import { Filters } from "../components/home/home";
import PropertyListFull from "../components/property/property-4/PropertyListFull";
import FilterNext from "../components/home/home/FilterNext";
import FilterNexts from "../components/home/home/component/FilterNext";
// import HeroCurosal from '../components/common/page-componets/HeroCurosal'

const Property = () => {
  const { isFilterMenuOpen } = useSelector(uiStore);
  const dispatch = useDispatch();
  const handleCloseFiltermenu = (e) => {
    if (e.target.classList.contains("filter-modal"))
      dispatch(closeFilterMenu());
  };

  const [layout, setLayout] = useState("grid");
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [properties, setProperties] = useState(dummyProperties);

  // Filter properties based on search query
  const filteredProperties = properties.filter((property) =>
    property.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {/* <HeroCurosal /> */}
      <div className="pt-48 px-[3%] md:px-[6%]">
        <h1 className="text-[12px]">HOME / <span className="text-primary">PROPERTY</span></h1>
        {/* <div className="pt-4 pb-4">

        <FilterNext/>
        </div> */}
        <div className="pt-4 pb-4">

<FilterNexts/>
</div>
        <div className="pt-8">
          <HeadeFilters layout={layout} setLayout={setLayout} />
        </div>
        {/* <div className="flex items-center justify-end mt-5">
          <input
            type="text"
            placeholder="Search properties..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border outline-none p-2 rounded-3xl dark:bg-transparent w-64 "
          />
        </div> */}
        <div className="grid md:grid-cols-3 gap-x-14 mt-5">
          <div className="md:col-span-3 mt-5 md:mt-0 h-fit md:sticky top-0 ">
            {layout === "grid" ? <PropertyList properties={filteredProperties} /> : <PropertyListFull properties={filteredProperties} />}
            {/* <Pagination itemsPerPage={8} pageData={filteredProperties} /> */}
          </div>
          <div className=" md:col-span-1 row-start-3 md:row-start-auto h-fit md:sticky top-0">
            <div
              className={`filter-modal ${isFilterMenuOpen && "open"}`}
              onClick={handleCloseFiltermenu}
            >
              <div className={`filter-dialog ${isFilterMenuOpen && "open"}`}>
                <div className="flex-center-between border-b dark:border-dark md:hidden">
                  <div
                    className="icon-box md:hidden"
                    onClick={() => dispatch(closeFilterMenu())}
                  >
                    <FiDelete />
                  </div>
                  <p className="uppercase">Filters</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Property;
