import GetInTouch from "../components/common/GetInTouch";
// import HeroCarousel from "../components/common/page-componets/HeroCurosal";
import { ContactInfo, Map } from "../components/contact";


const Contact = () => {
  return (
    <>
      {/* <HeroCarousel /> */}
      <div className="pt-48 px-[3%] md:px-[6%]">
        <h1 className="text-[12px]">HOME / <span className="text-primary">CONTACT US</span></h1>

        <ContactInfo />
        <Map />
        <GetInTouch />
      </div>
    </>
  );
};

export default Contact;
