import React, { useState, useEffect } from "react";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const BedsAndBathsDropdown = ({ activeDropdown, handleDropdownToggle, onSelectionChange }) => {
  const [selectedBed, setSelectedBed] = useState(null);
  const [selectedBath, setSelectedBath] = useState(null);
  const bedsButton = [1, 2, 3, 4, 5, 6, 7];
  const bathroomButton = [1, 2, 3, 4, 5];

  const handleBedsClick = (bed) => {
    const newSelectedBed = prev => (prev === bed ? null : bed);
    setSelectedBed(newSelectedBed);
    onSelectionChange(newSelectedBed, selectedBath);
  };

  const handleBathsClick = (bath) => {
    const newSelectedBath = prev => (prev === bath ? null : bath);
    setSelectedBath(newSelectedBath);
    onSelectionChange(selectedBed, newSelectedBath);
  };

  const resetSelections = () => {
    setSelectedBed(null);
    setSelectedBath(null);
    onSelectionChange(null, null); // Reset selections in parent
  };

  useEffect(() => {
    // Notify parent when the component mounts or selections change
    onSelectionChange(selectedBed, selectedBath);
  }, [selectedBed, selectedBath, onSelectionChange]);

  const formatSelection = (selected, type) => {
    if (selected === null) return `${type}`;
    return `${selected} ${type}`;
  };

  return (
    <div
      onClick={() => handleDropdownToggle("bedsbath")}
      className={`relative flex justify-between border rounded-lg w-full md:w-52 px-2 dark:bg-dark-light py-3 bg-slate-100 items-center cursor-pointer ${selectedBath || selectedBed ? 'border bg-primary/10' : ''}`}
    >
      <button className="border-b-2 border-transparent">
        {`${formatSelection(selectedBed, 'Bed')} & ${formatSelection(selectedBath, 'Bath')}`}
      </button>
      {activeDropdown === "bedsbath" ? <KeyboardArrowUp /> : <KeyboardArrowDown />}

      {activeDropdown === "bedsbath" && (
        <div className="bg-white dark:bg-dark-light border absolute left-0 top-full w-full md:w-96 p-4 rounded shadow-md z-10">
          <div className="flex flex-col gap-4">
            <div>
              <h3 className="font-bold mb-2">Bedrooms</h3>
              <div className="flex flex-wrap gap-2  md:justify-start">
                {bedsButton.map((bed) => (
                  <button
                    key={bed}
                    onClick={() => handleBedsClick(bed)}
                    className={`btn transition-colors p-2 border rounded-full ${
                      selectedBed === bed
                        ? "bg-primary text-white"
                        : "bg-gray-200 dark:bg-dark-light hover:bg-gray-300"
                    }`}
                    aria-label={`Select ${bed} bedroom${bed > 1 ? "s" : ""}`}
                  >
                    {bed}
                  </button>
                ))}
              </div>
            </div>

            <div className="mb-4">
              <h3 className="font-bold mb-2">Bathrooms</h3>
              <div className="flex flex-wrap gap-2  md:justify-start">
                {bathroomButton.map((bath) => (
                  <button
                    key={bath}
                    onClick={() => handleBathsClick(bath)}
                    className={`btn transition-colors p-2 border rounded-full ${
                      selectedBath === bath
                        ? "bg-primary text-white"
                        : "bg-gray-200 dark:bg-dark-light hover:bg-gray-300"
                    }`}
                    aria-label={`Select ${bath} bathroom${bath > 1 ? "s" : ""}`}
                  >
                    {bath}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <hr />

          <div className="flex justify-between dark:text-slate-50 mt-2">
            <button onClick={resetSelections} className="text-gray-600 dark:text-slate-50">
              Reset
            </button>
            <div
              onClick={() => handleDropdownToggle(null)} // close dropdown
              className="text-primary cursor-pointer"
              aria-label="Confirm selections"
            >
              <CheckCircleOutlineIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BedsAndBathsDropdown;
