import { BiCreditCard, BiGlobe, BiHomeAlt } from "react-icons/bi";












// [4:34 pm, 26/09/2024] Ashhad Rashid: SALE:
 
// https://www.propertyfinder.ae/en/broker/seaboard-properties-l-l-c-9575?properties%5Bfilter%5Bcategory_id%5D%5D=1
// [4:34 pm, 26/09/2024] Ashhad Rashid: RENT:
 
// https://www.propertyfinder.ae/en/broker/seaboard-properties-l-l-c-9575?properties%5Bfilter%5Bcategory_id%5D%5D=2



const AboutUs = () => {
  return (
    <>
      <div className="pt-16 ">
        <div className="flex flex-wrap ">
          <div className="relative flex-1 basis-[18rem] border  rounded-tr-2xl rounded-tl-2xl rounded-bl-2xl overflow-hidden">
            <img
              src="/images/property (16).jpg"
              alt=""
              className="object-cover w-full h-full "
            />

          </div>
          <div className="relative flex-1 basis-[22rem] pt-10 pl-5">
            <h1 className="heading">OUR MISSION</h1>
            <p className="mt-3">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo laborum
              earum saepe quibusdam, temporibus aut sapiente, ea alias libero,
              ipsam perferendis. Consectetur maiores, dicta.
            </p>
            <div className="mt-4">
              <div className="flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiHomeAlt />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    the perfect residency
                  </h1>

                </div>
              </div>

              <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiGlobe />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    global architect experts
                  </h1>

                </div>
              </div>

              <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiCreditCard />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    total payment transparency
                  </h1>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" pb-10">
        <div className="flex flex-wrap ">

          <div className="relative flex-1 basis-[18rem] pt-10 pl-5">

            <h1 className="heading">OUR VISION</h1>
            <p className="mt-3">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo laborum
              earum saepe quibusdam, temporibus aut sapiente, ea alias libero,
              ipsam perferendis. Consectetur maiores, dicta.
            </p>
            <div className="mt-4">
              <div className="flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiHomeAlt />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    the perfect residency
                  </h1>

                </div>
              </div>

              <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiGlobe />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    global architect experts
                  </h1>
                </div>
              </div>

              <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiCreditCard />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    total payment transparency
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="relative flex-1 basis-[22rem] border rounded-br-2xl rounded-tr-2xl rounded-bl-2xl overflow-hidden">
            <img
              src="/images/property (26).jpg"
              alt=""
              className="object-cover w-full h-full  "
            />

          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
