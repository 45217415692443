import React from "react";
import { FiChevronUp } from "react-icons/fi";

const BackToTopButton = ({ showButton }) => {
  return (
    <button
      className={`fixed bottom-0 right-0 grid mb-44 mr-11 z-30 rounded-full shadow back-to-top-btn w-10 h-10 place-items-center bg-primary shadow-primary/60 text-white ${showButton && "active"
        }`}
      onClick={() => window.scrollTo(0, 0)}
    >
      <FiChevronUp className=" text-white" />
    </button>
  );
};

export default BackToTopButton;
