import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { uiStore } from "../../features/uiSlice";
import classNames from "classnames";

const Dropdown = () => {
  const { currentLink, isDropdownOpen, position } = useSelector(uiStore);
  const { subLinks } = currentLink || {};
  const container = useRef(null);
  const [hoveredLinkId, setHoveredLinkId] = useState(null);
  const [isMiniSubLinksVisible, setIsMiniSubLinksVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (container.current) {
      container.current.style.left = `${position}px`;
    }
  }, [position]);

  const handleMouseEnter = (id) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setHoveredLinkId(id);
    setIsMiniSubLinksVisible(true);
  };

  const handleMouseLeave = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const id = setTimeout(() => {
      setHoveredLinkId(null);
      setIsMiniSubLinksVisible(false);
    }, 1000);
    setTimeoutId(id);
  };

  const handleDropdownMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsMiniSubLinksVisible(true);
  };

  const handleDropdownMouseLeave = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const id = setTimeout(() => {
      setHoveredLinkId(null);
      setIsMiniSubLinksVisible(false);
    }, 1000);
    setTimeoutId(id);
  };

  if (!currentLink) return null;

  return (
    <div
      className={classNames(
        "dropdown hidden z-50 transition-a fixed top-[70px] left-1/2-translate-x-1/2 !rounded-xl w-52 bg-white dark:bg-dark-light card-shadow dark:shadow-none",
        { show: isDropdownOpen && subLinks }
      )}
      ref={container}
      onMouseEnter={handleDropdownMouseEnter}
      onMouseLeave={handleDropdownMouseLeave}
    >
      <div className={classNames({ "p-2": subLinks })}>
        {subLinks?.map(({ id, linkText, url, miniSubLinks }) => (
          <div
            key={id}
            onMouseEnter={() => handleMouseEnter(id)}
            onMouseLeave={() => handleMouseLeave()}
            className="relative"
          >
            <NavLink
              end
              to={url}
              className="p-2 space-x-3 rounded-lg flex-align-center sm:cursor-pointer hover:bg-slate-100 dark:hover:bg-hover-color-dark before:!hidden"
            >
              {linkText}
            </NavLink>
            {/* {miniSubLinks && hoveredLinkId === id && (
              <div
                className={classNames(
                  "absolute top-0 left-full ml-2 z-10 transition-a !rounded-lg w-40 bg-white dark:bg-dark-light card-shadow dark:shadow-none",
                  { hidden: !isMiniSubLinksVisible }
                )}
              >
                <div className={classNames({ "p-2": miniSubLinks })}>
                  {miniSubLinks?.map(({ id, linkText, url }) => (
                    <NavLink
                      key={id}
                      end
                      to={url}
                      className="block p-2 space-x-3 rounded-lg flex-align-center sm:cursor-pointer hover:bg-slate-100 dark:hover:bg-hover-color-dark before:!hidden"
                    >
                      {linkText}
                    </NavLink>
                  ))}
                </div>
              </div>
            )} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
