import { BiBed, BiMap } from "react-icons/bi";
import { Link } from "react-router-dom";
import WOW from 'wow.js';
import { TbDimensions } from "react-icons/tb";
import { useEffect, useState } from "react";
import { FaRegArrowAltCircleRight, FaRegArrowAltCircleLeft } from "react-icons/fa";
import CardLabels from "./CardLabels";
import { MdOutlineBed, MdOutlineBathtub } from "react-icons/md";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const SingleProductCard = ({
  name,
  location,
  price,
  dealer,
  distance,
  purpose,
  number_of_beds,
  number_of_bathrooms,
  dimensions,
  image,
  basis,
  dd_MM_yy,
  id,
  bedsMaid,
  ...data
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const add = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % image.length);
  };

  const subtract = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + image.length) % image.length);
  };

  useEffect(() => {
    const wow = new WOW({
      live: false,
      offset: 100,
      mobile: true
    });
    wow.init();

    return () => {};
  }, []);

  const formatPrice = (price) => {
    const strPrice = price.toString();
    const lastThreeDigits = strPrice.slice(-3);
    const otherDigits = strPrice.slice(0, -3);
    
    const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    
    return otherDigits ? `${formattedOtherDigits},${lastThreeDigits}` : lastThreeDigits;
  };

  return (
    <div
      style={{ visibility: "visible" }}
      className={`flex-1 ${basis ? basis : "basis-[28rem] "} shadow-light dark:border-card-dark border rounded-lg overflow-hidden relative group`}
      data-wow-delay="0.5s"
    >
      <div className="group !opacity-100 overflow-hidden relative">
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={image.length}
          className="w-full h-[250px]"
        >
          <Slider onAfterSlide={index => setCurrentSlide(index)}>
            {image.map((imgSrc, index) => (
              <Slide key={index}>
                <img
                  src={imgSrc}
                  alt={`${name} image ${index + 1}`}
                  className="object-cover w-full h-[250px] transition-transform duration-300"
                />
              </Slide>
            ))}
          </Slider>
          <ButtonBack onClick={subtract} className="absolute top-1/2 left-0 transform -translate-y-1/2 h-12 w-12 flex items-center justify-center text-white text-3xl rounded-full">
            <FaRegArrowAltCircleLeft />
          </ButtonBack>
          <ButtonNext onClick={add} className="absolute top-1/2 right-0 transform -translate-y-1/2 h-12 w-12 flex items-center justify-center text-white text-3xl rounded-full">
            <FaRegArrowAltCircleRight />
          </ButtonNext>
          <div className="absolute top-0 left-0 w-full flex items-center justify-end">
            <p className="text-white bg-black/50 p-1 rounded-md">{currentSlide + 1} / {image.length}</p>
          </div>
        </CarouselProvider>
        <div className="absolute bottom-0 left-0 w-full">
          <div className="text-white linear-custom flex-align-center gap-x-2">
            <BiMap />
            <p>{location}</p>
          </div>
        </div>
      </div>
      <CardLabels purpose={purpose} distance={distance} />
      <div className="p-3">
        <Link to={'/property/' + id} className="group-hover:text-primary transition-a">
          <h1 className="text-2xl font-extrabold">
            <span className="text-primary">
              AED : {formatPrice(price)} {dd_MM_yy && <span className="text-slate-700 dark:text-white">{` / ${dd_MM_yy}`}</span>}
            </span>
          </h1>
          <h1 className="text-lg font-bold capitalize">{name}</h1>
        </Link>
        <div className="flex justify-between mt-3">
          <div className="flex-align-center gap-x-2">
            <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
              <MdOutlineBed />
            </div>
            <p className="text-sm">{number_of_beds} {bedsMaid}</p>
          </div>
          <div className="flex-align-center gap-x-2">
            <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
              <MdOutlineBathtub />
            </div>
            <p className="text-sm">{number_of_bathrooms} Bathrooms</p>
          </div>
          <div className="flex-align-center gap-x-2">
            <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
              <TbDimensions />
            </div>
            <p className="text-sm">{dimensions} Sqft</p>
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <button className="btn btn-primary">
            <Link to={'/property/' + id} className="!opacity-100">Details</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleProductCard;
