import { property } from "../../../data/dummyData";
import SingleProductCard from "./SingleProductCard";
import { Link } from "react-router-dom";
const Featured = () => {
  return (
    <div className="pt-10 pb-16">
      <div className="text-center">
        <h1 className="heading">Find Your Dream House</h1>
        {/* <h1 className="mx-auto sub-heading">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi error ex veritatis labore iste alias consequuntur praesentium sunt totam sapiente suscipit laudantium explicabo, doloremque ad non ipsum. Quibusdam, suscipit beatae.</h1> */}
      </div>
      <div className="flex flex-wrap gap-4 mt-8">
        {property.slice(0, 3).map((featured) => (
          <SingleProductCard key={featured.id} {...featured} />
        ))}
      </div>
      <div className="flex justify-end">
        <Link to="/property">
          <button className=" mt-4 btn btn-primary">Vist All Property</button>
        </Link>
      </div>
    </div>
  );
};

export default Featured;
