
import { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger'


const Counter = () => {
  const [counterState, setCounterState] = useState(false);
  return (
    <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
      <div
        className="flex-wrap justify-center h-[30vh] gap-2 px-4 py-8 flex-align-center sm:justify-around rounded-lg overflow-hidden"
        style={{
          position: "relative",
          backgroundImage: "url('/images/property (11).jpg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed"
        }}
      >

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)"
          }}
        ></div>

        {/* Content */}
        <div className="text-center">
          <h1 className="heading !text-slate-100">{counterState &&
            <CountUp end={13} duration={5} />}k+</h1>
          <p className="text-slate-100">Renovation Projects</p>
        </div>
        <div className="text-center">
          <h1 className="heading !text-slate-100">{counterState &&
            <CountUp end={100} duration={5} />}+</h1>
          <p className="text-slate-100">Team Members</p>
        </div>
        <div className="text-center">
          <h1 className="heading !text-slate-100">{counterState &&
            <CountUp end={10} duration={5} />}k+</h1>
          <p className="text-slate-100">Projects Completed</p>
        </div>
        <div className="text-center">
          <h1 className="heading !text-slate-100">{counterState &&
            <CountUp end={100} duration={5} />}%</h1>
          <p className="text-slate-100">Satisfied Clients</p>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default Counter;
